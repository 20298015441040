<template>
  <v-card>
    <v-skeleton-loader type="table-thead" v-if="isLoading" />

    <v-row class="ma-0" v-else>
      <v-col
        cols="4"
        sm="4"
        md="2"
        lg="2"
        xl="2"
        :class="[
          'card-info',
          item.situation === 'Informações de chamados' &&
            'color-outlined color--primary'
        ]"
        v-for="(item, index) in items"
        :key="index"
        @click="redirect(item)"
      >
        <v-icon
          :class="[
            'card-info--icon',
            item.situation === 'Em andamento' && 'btn-rotate'
          ]"
          :color="getColor(item.situation)"
          size="24"
        >
          {{ getIcon(item.situation) }}
        </v-icon>
        <div>
          <h4 class="card-info--title" v-if="item.value >= 0">
            {{ item.value }}
          </h4>
          <small
            v-if="item.situation"
            :class="
              item.situation === 'Informações de chamados' &&
              'card-info--information'
            "
          >
            {{ item.situation }}
          </small>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { DashboardService } from '@/services/api/dashboard';

export default {
  props: {
    contractId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    isLoading: false,
    items: []
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      try {
        this.isLoading = true;
        const dashboardService = new DashboardService(false);
        const { status, data } = await dashboardService.getSupportCardData();

        if (status === 200) {
          this.items = data;
          this.items.unshift({
            color: 'primary',
            icon: 'fa-regular fa-envelope-open',
            situation: 'Informações de chamados',
            value: null
          });
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    redirect(item) {
      this.$router.push({
        name: 'suportes',
        query: { situation: item.situation }
      });
    },

    getColor(situation) {
      let color = {
        Novo: 'info',
        'Em andamento': 'primary',
        Finalizado: 'success',
        Resolvido: 'green',
        'Aguardando operadora': 'error',
        default: 'primary'
      };

      return color[situation] || color['default'];
    },

    getIcon(situation) {
      let color = {
        Novo: 'fa-solid fa-envelope-open-text',
        'Em andamento': 'fa-solid fa-spinner',
        Finalizado: 'fa-regular fa-thumbs-up',
        Resolvido: 'fa-regular fa-circle-check',
        'Aguardando operadora': 'fa-regular fa-clock',
        default: 'fa-regular fa-envelope-open'
      };

      return color[situation] || color['default'];
    }
  },

  watch: {
    contractId() {
      this.search();
    }
  }
};
</script>

<style lang="scss">
.card-info {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
  gap: 1rem;
  padding: 1rem 0.8rem;

  @media (max-width: 959px) {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-bottom: 1px solid;
    }

    &:nth-child(3) {
      border-right: 0 !important;
    }
  }

  &:not(:first-child, :last-child) {
    border-right: 1px solid;
  }

  &:not(:first-child) {
    cursor: pointer;
  }

  &--information {
    color: var(--v-primary-base);
    font-size: 0.9rem;
    font-weight: 400;
  }

  &--title {
    font-size: 1.5rem;
    font-weight: 300;
  }
}

.theme--light {
  .card-info {
    $this: &;

    &:hover:not(:first-child) {
      background: $gray--10;
    }

    &:not(:last-child) {
      border-color: $gray--15;
    }

    &--title {
      color: $gray--40;
    }

    small:not(#{$this}--information) {
      color: $gray--20;
    }
  }
}

.theme--dark {
  .card-info {
    $this: &;

    &:hover:not(:first-child) {
      background: $bg-dark-active;
    }

    &:not(:last-child) {
      border-color: $gray--40;
    }

    small:not(#{$this}--information) {
      color: $gray--20;
    }
  }
}
</style>
