var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table-thead"}}):_c('v-row',{staticClass:"ma-0"},_vm._l((_vm.items),function(item,index){return _c('v-col',{key:index,class:[
        'card-info',
        item.situation === 'Informações de chamados' &&
          'color-outlined color--primary'
      ],attrs:{"cols":"4","sm":"4","md":"2","lg":"2","xl":"2"},on:{"click":function($event){return _vm.redirect(item)}}},[_c('v-icon',{class:[
          'card-info--icon',
          item.situation === 'Em andamento' && 'btn-rotate'
        ],attrs:{"color":_vm.getColor(item.situation),"size":"24"}},[_vm._v(" "+_vm._s(_vm.getIcon(item.situation))+" ")]),_c('div',[(item.value >= 0)?_c('h4',{staticClass:"card-info--title"},[_vm._v(" "+_vm._s(item.value)+" ")]):_vm._e(),(item.situation)?_c('small',{class:item.situation === 'Informações de chamados' &&
            'card-info--information'},[_vm._v(" "+_vm._s(item.situation)+" ")]):_vm._e()])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }